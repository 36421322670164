/* You can add global styles to this file, and also import other style files */
@import "~@angular/material/prebuilt-themes/indigo-pink.css";
@import "../assets/css/main.scss";

iframe {
    width: 100%;
    height: 100%;
    overflow: hidden;
    border: none;
    min-height: 500px;
}

.owl-carousel {
    z-index: 0 !important;
}

#ngb-live {
    display: none;
}

.search-field {
    input {
        margin-top: 8px;
        border: 1px solid var(--Neutral-Dark-Grey-Medium, #C3C3C3);
        background: var(--Neutral-Dark-Grey-Lightest, #FFF);
    }
    .input-with-icon .input-icon {
        z-index: 1;
    }
}

.home-main-banner-container {
    .search-field-dates, .passenger-types-select-container {
        margin-bottom: 8px !important;
    }

    .search-field-return-date-g {
        padding-left: 4px !important;
    }

    .search-field-departure-date-g {
        padding-right: 4px !important;
    }
}

.sale-header-summary-container {
    .search-field-return-date-g {
        padding-left: 0px !important;
    }

    .search-field-departure-date-g {
        padding-right: 0px !important;
    }
}

.service-list-trip .trip-main-new-card .trip-route {
    max-width: 210px;
}

.trip-discount-type, .trip-free-type {
    color: #fff;
    font-size: 12px;
    font-weight: 700;    

    span {
        background: #3EAB36;
        padding: 5px 8px;
        border-radius: 3px;
    }
}

.trip-discount-type span {
    background: #187BD1 !important;
}

.payment-sub-form {
    border-radius: 3px !important;
    padding: 24px !important;
    background-color: #eee !important;
}

.total-viva {
    color: #EA0F6B !important;
    text-align: right;
    font-family: Montserrat;
    font-size: 12px;
    font-style: normal;
    font-weight: 700 !important;
    line-height: normal;
}

.sale-summary b {
    color: #333;
    font-family: Montserrat;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.summary-info-text {
    white-space: normal !important;
}

@media screen and (max-width: 800px) {
    .sale-header-summary-container .search-field-departure-date-g {
        padding-right: 15px !important;
    }

    .sale-header-summary-container .search-field-return-date-g {
        padding-left: 15px !important;
    }

    .service-list-trip.opened .trip-main {
        margin-bottom: 0 !important;
    }

    .service-list-trip .trip-details-container {
        margin: -35px 0 15px !important; 
    }

  .home-main-banner-container {
    .search-field-return-date-g {
        padding-left: 15px !important;
      }

      .search-field-departure-date-g {
          padding-right: 15px !important;
     }
  }

 .search-home {
        background: rgba(255, 255, 255, 0.70);
        box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.25);
    }

    .trip-discount-type, .trip-free-type {
        width: 100%;
        order: -1;
        margin-top: 50px;
    }
}

$color-green: #22c15e;
$color-gray: #c3c3c3;
$color-red: #ff6027;
$color-availability-background: #f4f4f4;

.seat-map-availability {
    max-width: 650px;

    .seat-availability {
        .seat-details {
            position: relative;
            border-radius: 3px;
            background-color: #f4f4f4;
            margin-bottom: 4px;
            margin-top: 4px;
            
            &::before {
                content: '';
                display: block;
                width: 3px;
                height: 20px;
                border-radius: 0 3px 3px 0;
                position: absolute;
                top: calc(50% - 10px);
                left: 0;
                background: $color-gray;
            }

            .seat-details-legend {
                display: inline-block;
                width: 10px;
                height: 10px;
                border-radius: 3px;
                margin-right: 5px;

                &.legend-free {
                    background: $color-green;
                }

                &.legend-occupied {
                    background: $color-red;
                }
            }
        }
    }
}

.px-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
}

.py-2 {
    padding-top: .5rem !important;
    padding-bottom: .5rem !important;
}

.mr-3 {
    margin-right: 1rem !important;
}

.d-flex {
    display: flex !important;
}

// HEADER GUANABARA
@media (min-width: 1200px) {
    .container-header-guanabara {
        max-width: 1240px !important;
    }
}
